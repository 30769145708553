import React from "react";
import Layout from "../components/layout"
import SEO from "../components/seo";
import alex from "../images/alex.webp";
import julien from "../images/julien.jpg";
import archie from "../images/archie.jpg";
import ollie from "../images/ollie.jpg";
import debora from "../images/debora.jpg";
import cms from "../components/cms/cms";

const AboutPage = () =>
  <Layout>
    <SEO
      keywords={[`${cms.keyword} jobs`, `${cms.keyword} developer jobs`, `remote ${cms.keyword} jobs`, `about`, `alex garella`]}
      title="About us"
      description={`${cms.siteUrl} is the go-to hiring platform for ${cms.keyword} Engineering Talent.`}
    />
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h1 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl">About us</h1>
            <p className="text-xl leading-7 text-gray-500">
              {cms.siteUrl} is the go-to hiring platform for {cms.keyword} Engineering Talent.
              <br />
              <br />
              We reach tens of thousands of {cms.keyword} Engineers through our website, social media channels and recruitment network every month.
              <br />
              <br />
              We provide Recruitment, Team Extension and Job Board services in the {cms.keyword} Engineering space.
              <br />
              <br />
              Reach out at <a className="font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150" href={`mailto:hello@${cms.siteUrl}`}>hello@{cms.siteUrl}</a> to find out how we can help you with your {cms.keyword} hiring needs!
            </p>
          </div>
          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0">
              <li className="sm:py-8">
                <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                  <div className="relative pb-2/3 sm:pt-2/3">
                    <img className="absolute inset-0 object-cover h-full w-full shadow-lg rounded-lg" src={alex} alt="Alex Garella" />
                  </div>
                  <div className="sm:col-span-2">
                    <div className="space-y-4">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h4>Alex Garella</h4>
                        <p className="text-gray-600">Founder
                          {cms.keyword === "Scala" ? " / Scala Developer" : ""}
                        </p>
                      </div>
                      <div className="text-lg leading-7">
                        <p className="text-gray-500">
                          I&apos;m a Software Engineer with multiple years of experience at various startups. When looking for {cms.keyword} jobs, I realized there wasn&apos;t a single great place to find them, so I decided to build one myself!
                        </p>
                      </div>
                      <ul className="flex space-x-5">
                        <li>
                          <a
                            href={cms.keyword === "Rust" ? "https://twitter.com/rustjobs_dev" : "https://twitter.com/ScalaJobsDev"}
                            target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span className="sr-only">Twitter</span>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/in/agarella/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="sm:py-8">
                <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                  <div className="relative pb-2/3 sm:pt-2/3">
                    <img className="absolute inset-0 object-cover h-full w-full shadow-lg rounded-lg" src={julien} alt="Julien Truffaut" />
                  </div>
                  <div className="sm:col-span-2">
                    <div className="space-y-4">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h4>Julien Truffaut</h4>
                        <p className="text-gray-600">Co-founder
                          {cms.keyword === "Scala" ? " / Scala Developer" : ""}
                        </p>
                      </div>
                      <div className="text-lg leading-7">
                        <p className="text-gray-500">
                          I am a backend engineer with more than 10 years of experience from start-ups to tech giants like Amazon.
                          <br />
                          <br />
                          I love teaching the practical side of functional programming in Scala. I created my own online course at FP-Tower which is used by many companies to train junior and mid-level Scala developers.
                          <br />
                          <br />
                          I am also the author of the popular open-source library Monocle, an optics library that helps functional Scala developers easily traverse, transform, and access deeply nested data structures.
                        </p>
                      </div>
                      <ul className="flex space-x-5">
                        <li>
                          <a href="https://twitter.com/JulienTruffaut" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span className="sr-only">Twitter</span>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/in/julientruffaut/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="sm:py-8">
                <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                  <div className="relative pb-2/3 sm:pt-2/3">
                    <img className="absolute inset-0 object-cover h-full w-full shadow-lg rounded-lg" src={archie} alt="Archie Amari" />
                  </div>
                  <div className="sm:col-span-2">
                    <div className="space-y-4">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h4>Archie Amari</h4>
                        <p className="text-gray-600">Head of Talent
                        </p>
                      </div>
                      <div className="text-lg leading-7">
                        <p className="text-gray-500">
                          Passionate talent hunter 🕵️‍♂️
                          <br />
                          <br />
                          I'm a huge advocate for venturing deep into open-source projects and expanding networks in other social channels such as Reddit, Mastodon, and Twitter where the hidden talent remains unfound.
                          <br />
                          <br />
                          I've had the pleasure of supporting more than 30 companies worldwide to flourish when scaling their technical departments, collaborating directly with CEOs, CTOs, Director/VP of Engineering, and HR/TAs to close niche engineering positions across Scala, Rust, Machine Learning & AI almost instantaneously.
                          <br />
                          <br />
                          As a result, I've successfully helped a multitude of companies save tremendous amounts of time when expanding, reduced yearly external hiring costs and drastically improved their CV-to-placement ratios.
                        </p>
                      </div>
                      <ul className="flex space-x-5">
                        <li>
                          <a href="https://twitter.com/Archie_Amari" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span className="sr-only">Twitter</span>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/in/archie-amari-95a883220/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="sm:py-8">
                <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                  <div className="relative pb-2/3 sm:pt-2/3">
                    <img className="absolute inset-0 object-cover h-full w-full shadow-lg rounded-lg" src={ollie} alt="Ollie Morado" />
                  </div>
                  <div className="sm:col-span-2">
                    <div className="space-y-4">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h4>Ollie Morado</h4>
                        <p className="text-gray-600">Talent Manager
                        </p>
                      </div>
                      <div className="text-lg leading-7">
                        <p className="text-gray-500">
                          Interested in all things Scala & Rust and have dedicated time to research different areas of the tech ecosystems.
                          <br />
                          <br />
                          Assisting companies within the Scala & Rust space build technology-driven teams to face a variety of challenges - a majority of these companies are active community leaders, from open-source contributions to hosting tech meetups.
                          <br />
                          <br />
                          Believer in taking time to establish a firm understanding of internal roadmaps and the technical anticipations for the teams I personally collaborate with - ensuring I'm connecting exceptional talent to the right mission.
                        </p>
                      </div>
                      <ul className="flex space-x-5">
                        <li>
                          <a href="https://twitter.com/olliemorado" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span className="sr-only">Twitter</span>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/in/ojmorado/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="sm:py-8">
                <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                  <div className="relative pb-2/3 sm:pt-2/3">
                    <img className="absolute inset-0 object-cover h-full w-full shadow-lg rounded-lg" src={debora} alt="Debora da Costa" />
                  </div>
                  <div className="sm:col-span-2">
                    <div className="space-y-4">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h4>Debora da Costa</h4>
                        <p className="text-gray-600">Talent Manager
                        </p>
                      </div>
                      <div className="text-lg leading-7">
                        <p className="text-gray-500">
                          With a blend of in-house and agency experience, I understand the intricacies and demands of talent acquisition within a dynamic and fast-paced environment.
                          <br />
                          <br />
                          My competency lies in formulating and executing impactful recruitment strategies to attract professionals, nurture partnerships with industry leaders, and consistently engage in sourcing channels to connect with suitable candidates.
                          <br />
                          <br />
                          Genuine passion and constant improvement of industry knowledge are vital in identifying candidates with the requisite technical and company ethos.
                        </p>
                      </div>
                      <ul className="flex space-x-5">
                        <li>
                          <a href="https://x.com/Deboracdc" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span className="sr-only">Twitter</span>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/in/debora-da-costa-026a5315a/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </Layout>

export default AboutPage;